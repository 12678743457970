exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-work-case-studies-jsx": () => import("./../../../src/pages/work/case-studies.jsx" /* webpackChunkName: "component---src-pages-work-case-studies-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-solution-jsx": () => import("./../../../src/templates/solution.jsx" /* webpackChunkName: "component---src-templates-solution-jsx" */),
  "component---src-templates-technology-jsx": () => import("./../../../src/templates/technology.jsx" /* webpackChunkName: "component---src-templates-technology-jsx" */)
}

